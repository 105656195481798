import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { SupportedLocale } from '@zep/consts/localize.ts';
import { PricingSection } from '@zep/module/payment';
import { Button } from '@zep/ui';
import { cn } from '@zep/utils';
import { useTranslation } from 'next-i18next';

import { SectionItem } from './SectionItem';

export const PricingSection2 = () => {
  return (
    <PricingSection
      className={cn(
        'flex flex-col gap-[24px] bg-white ',
        'max-[1024px]:px-xl min-[1025px]:px-3xl',
        'min-[891px]:px-xl',
        'min-[751px]:pt-3xl min-[751px]:pb-4xl min-[751px]:px-xl',
        'max-[750px]:py-2xl max-[750px]:px-md',
      )}>
      <FunctionCards />
      <MoreCard />
    </PricingSection>
  );
};

export const FunctionCards = () => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation();

  const locale: SupportedLocale = resolvedLanguage as SupportedLocale;

  const bgImages: Record<
    SupportedLocale,
    Record<'1' | '2', { normal: string; mobile: string }>
  > = {
    ko: {
      '1': {
        normal: '/assets/pricing/function-1-ko.png',
        mobile: '/assets/pricing/function-1-ko-mb.png',
      },
      '2': {
        normal: '/assets/pricing/function-2-ko.png',
        mobile: '/assets/pricing/function-2-ko-mb.png',
      },
    },
    en: {
      '1': {
        normal: '/assets/pricing/function-1-ko.png',
        mobile: '/assets/pricing/function-1-ko-mb.png',
      },
      '2': {
        normal: '/assets/pricing/function-2-en.png',
        mobile: '/assets/pricing/function-2-en-mb.png',
      },
    },
    ja: {
      '1': {
        normal: '/assets/pricing/function-1-ko.png',
        mobile: '/assets/pricing/function-1-ko-mb.png',
      },
      '2': {
        normal: '/assets/pricing/function-2-ja.png',
        mobile: '/assets/pricing/function-2-ja-mb.png',
      },
    },
  };

  return (
    <>
      <PictureCard
        key={3}
        containerProps={{
          style: {
            background: 'linear-gradient(90deg, #FDE8FD 0%, #F8F9FC 100%)',
          },
        }}
        title={
          <span className={'leading-[1.2] text-[#B000B0]'}>
            {t('home.pricing.moreManyMap', '더 많은 퀴즈 맵')}
          </span>
        }
        description={t(
          'home.pricing.everyMontUpdateMap',
          '매 달 업데이트되는 새로운 형식의 맵을 사용해보세요.',
        )}
        bgImages={{
          normal: bgImages[locale]['1']['normal'],
          mobile: bgImages[locale]['1']['mobile'],
        }}
      />
      <PictureCard
        key={2}
        containerProps={{
          style: {
            background: 'linear-gradient(90deg, #E8FCEA 0%, #F8F9FC 100%)',
          },
        }}
        title={
          <span className={'leading-[1.2] text-[#10988D]'}>
            {t('home.pricing.infinityGenerateAiQuiz', '무제한 AI 문제 생성')}
          </span>
        }
        description={t(
          'home.pricing.infinityGenerateAiQuizPropose',
          '무제한으로 AI 문제를 생성하여 다양한 퀴즈를 쉽게 만들어보세요',
        )}
        bgImages={{
          normal: bgImages[locale]['2']['normal'],
          mobile: bgImages[locale]['2']['mobile'],
        }}
      />
    </>
  );
};

const PictureCard = (props: {
  containerProps?: ComponentPropsWithoutRef<'div'>;
  title: ReactNode;
  description: ReactNode;
  bgImages: { mobile: string; normal: string };
  textWrapperProps?: ComponentPropsWithoutRef<'div'>;
  HeaderButton?: ReactNode;
}) => {
  return (
    <SectionItem
      {...props.containerProps}
      className={cn(
        'max-w-[1000px] w-full',
        'flex items-center rounded-[24px] outline outline-2 outline-[rgba(28,27,31,0.04)]',
        'max-[750px]:flex-col min-[751px]:flex-row',
        'min-[751px]:h-[240px] min-[1025px]:h-[280px]',
        props.containerProps?.className,
      )}>
      <div
        className={cn(
          'flex flex-col flex-1 min-w-[240px]',
          'gap-xs min-[751px]:gap-md',
          'w-full',

          'max-[750px]:items-center',
          'min-[751px]:items-start',

          'min-[1025px]:py-xl min-[1025px]:pl-3xl',
          'min-[751px]:py-xl min-[751px]:pl-2xl',
          'max-[750px]:px-xl max-[750px]:pt-xl max-[750px]:pb-xs',
          props.textWrapperProps?.className,
        )}>
        {props.HeaderButton}

        <ConditionalWrapper condition={!!props.HeaderButton}>
          <span
            className={cn(
              'font-extrabold leading-[130%] text-[22.5px] min-[751px]:text-[28.125px] min-[1025px]:text-[30px]',
              'text-center min-[751px]:text-left',
            )}>
            {props.title}
          </span>

          <span
            className={cn(
              'whitespace-normal text-[22.5px] font-medium max-[750px]:whitespace-normal !max-[750px]:break-all',
              'max-[750px]:text-[15px] min-[751px]:text-[16.875px] min-[1025px]:text-[18px]',
              'text-center min-[751px]:text-left',
            )}>
            {props.description}
          </span>
        </ConditionalWrapper>
      </div>
      <div
        className={cn(
          'min-[1025px]:flex-0 w-full min-[1025px]:w-[520px]',
          'min-[751px]:w-[445.714px]',
        )}>
        <picture
          className={cn('flex w-auto rounded h-auto', [
            'min-[560px]:h-[286px] min-[751px]:h-[240px] min-[1025px]:h-[280px]',
          ])}>
          <source srcSet={props.bgImages.mobile} media="(max-width: 750px)" />
          <source srcSet={props.bgImages.normal} media="(min-width: 751px)" />
          <img
            className={cn(
              'w-full rounded-r-[24px] max-[751px]:rounded-b-[24px] min-[750px]:rounded-r-[24px]',
            )}
            alt={'function-images'}
            src={props.bgImages.normal}
          />
        </picture>
      </div>
    </SectionItem>
  );
};

const ConditionalWrapper = ({
  condition,
  children,
}: {
  condition: boolean;
  children: ReactNode;
}) =>
  condition ? (
    <div className={'flex flex-col gap-xs'}>{children}</div>
  ) : (
    <>{children}</>
  );

const MoreCard = () => {
  const { t } = useTranslation();
  return (
    <SectionItem
      style={{
        borderColor: 'rgba(28, 27, 31, 0.04)',
        background: 'linear-gradient(270deg, #F8F9FC 0%, #F0F2F6 100%)',
      }}
      className={
        'fle x flex w-full max-w-[1000px] flex-col  items-center gap-lg rounded-[24px] px-2xl py-xl outline outline-2 outline-[rgba(28,27,31,0.04)] '
      }>
      <div className={'flex flex-col items-center gap-xs'}>
        <div
          className={
            'text-center text-[22.5px] font-extrabold text-gray-600 min-[751px]:text-left min-[751px]:text-[24px]'
          }>
          {t('home.pricing.moreFunctionComing', '더 많은 기능이 찾아옵니다')}
        </div>
        <div
          className={
            'text-center text-[15px] font-medium text-gray-800 min-[751px]:text-left min-[751px]:text-[16px] '
          }>
          {t(
            'home.pricing.variousFunctionProposal',
            '다양한 프로 기능을 제일 먼저 만나보세요',
          )}
        </div>
      </div>
      <Button
        style={{
          background: 'rgba(103, 88, 255, 0.08)',
        }}
        className={
          'h-[36px] w-fit cursor-auto text-[16.875px]  font-extrabold uppercase text-[#928DBC] min-[1024px]:text-heading-sm'
        }>
        COMING SOON
      </Button>
    </SectionItem>
  );
};
