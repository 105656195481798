import { ComponentPropsWithoutRef } from 'react';
import Footer from '@zep/components/Footer.tsx';
import { PricingSection3 } from '@zep/module/payment/Pricing/PricingSection3.tsx';
import { cn } from '@zep/utils';

import S from './PricingSection1/PricingSection1.module.scss';
import { PricingBanner, PricingSection1 } from './PricingSection1';
import { PricingSection2 } from './PricingSection2.tsx';

export const Pricing = (props: PricingProps) => {
  return (
    <div className={cn(S.container)}>
      <PricingSection1 />
      <PricingBanner backgroundStyle="linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.85) 15%, rgba(255, 255, 255, 0.85) 80%, #FFF 100%), linear-gradient(90deg, #8478FF 0%, #3DA9FF 100%)" />
      <PricingSection2 />
      <PricingSection3 {...props} />
      <Footer />
    </div>
  );
};

export const PricingSection = (props: ComponentPropsWithoutRef<'section'>) => {
  const { children, ...rest } = props;

  return (
    <section
      {...rest}
      className={cn(
        'flex flex-col self-stretch items-center word-break-all',
        'max-[890px]:px-xl min-[891px]:px-3xl',
        'py-md min-[750px]:py-2xl min-[751px]:py-3xl',
        props.className,
      )}>
      {children}
    </section>
  );
};

export type PricingProps = {
  content: PricingContent;
};

export type PricingContent = {
  questions: [
    {
      question: { ko: string; en: string; ja: string };
      answer: { ko: string; en: string; ja: string }[];
    },
  ];
};
